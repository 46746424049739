import { Header, Section } from "../../../components/layout";
import type { IPolicy } from "../../../types";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { Button, Popover, TableCell, TableRow } from "../../../components/util";
import { InviteUser, PaginatedResults } from "../../../components/content";
import organisationService from "../../../services/organisation/organisation.service";
import { invitationStatus } from "../../../helpers";
import { formatDateTime } from "../../../helpers/dateTime";

import InvitationService, {
  Invitation,
} from "../../../services/invitation/invitation.service";

import * as Styled from "./styles.css";

type InvitationsTabProps = {
  policy: IPolicy;
};

type InvitationsListProps = {
  policy: IPolicy;
};

const InvitationsTab = ({ policy }: InvitationsTabProps) => {
  const history = useHistory();

  const createInvitation = () =>
    history.push(`/invitations/policy-holder/invite?id=${policy.id}`, {
      type: "client",
    });

  const InviteButton = ({ policy }: { policy: IPolicy }) => {
    switch (invitationStatus(policy)) {
      case "doNotInvite":
        return (
          <Button color="accent" variant="contained" disabled>
            Invitations disabled
          </Button>
        );
      case "invite":
        return (
          <Button color="accent" onClick={createInvitation} variant="contained">
            Invite client
          </Button>
        );
      case "invited":
      default:
        return null;
    }
  };

  return (
    <>
      <Header
        title="Invitations"
        titleIcon="mail"
        titleIconPosition="start"
        type="section"
        actions={<InviteButton policy={policy} />}
      />

      <Section>
        <InvitationsList policy={policy} />
      </Section>
    </>
  );
};

const InvitationsList = ({ policy }: InvitationsListProps) => {
  const { search } = useLocation();

  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    urlParams?.searchTerm ? urlParams?.searchTerm : "",
  );

  useEffect(() => {
    setSearchTerm(urlParams?.searchTerm);
  }, [urlParams?.searchTerm]);

  const invitationsRow = (invitationsSlice: Invitation[]) => {
    return (
      <>
        {invitationsSlice.map((invitation: Invitation) => (
          <TableRow key={invitation.id}>
            <TableCell>
              <Styled.Link
                href={`mailto:${invitation.email}`}
                title={invitation.email}
              >
                {invitation.email}
              </Styled.Link>
            </TableCell>
            <TableCell>{invitation.sender?.email}</TableCell>
            <TableCell>{invitation.status}</TableCell>
            <TableCell>{formatDateTime(invitation.created_at)}</TableCell>
            <TableCell>
              <OverflowMenuButton policy={policy} invitation={invitation} />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <PaginatedResults
      id={`portfolio-client-${policy.policy_number}`}
      searchTerm={searchTerm}
      searchService={InvitationService.searchByPolicyNumber}
      service={InvitationService.getByPolicyNumber}
      serviceParams={{ policyNumber: policy.policy_number }}
      tableColumns={[
        { title: "Invitee", expand: true },
        { title: "Inviter", expand: true },
        { title: "Status", collapse: true },
        { title: "Created", collapse: true },
        { title: "Actions", collapse: true },
      ]}
      tableRows={invitationsRow}
      missing={{
        icon: "checklist",
        type: "invitations",
        message: "This is where we'll show all invitations.",
      }}
    />
  );
};

export const OverflowMenuButton = ({
  policy,
  invitation,
}: {
  policy: IPolicy;
  invitation: Invitation;
}) => {
  const hasInvitations = policy?.invitations?.some(
    (invite: Invitation) => invite && invite.status === "active",
  );

  const canResendOrCancel = hasInvitations && invitation.status === "active";
  const canViewPolicy = policy.type !== "wc";
  const popupEnabled = canResendOrCancel || canViewPolicy;

  const openPolicyLink = (policy_id: string, policy_number: string) => {
    return organisationService.getPolicyLink(policy_id, policy_number);
  };

  return (
    <Popover
      alignment="end"
      offsetX={40}
      offsetY={-25}
      trigger={
        <Styled.MoreOptionIconButton
          disabled={!popupEnabled}
          icon="kebab-horizontal"
        >
          Pop
        </Styled.MoreOptionIconButton>
      }
    >
      <Styled.MoreOptionSelectMenu>
        {canResendOrCancel && (
          <>
            <InviteUser
              action="resend"
              context="overflow"
              invitation={invitation}
              type="client"
            />
            <InviteUser
              action="cancel"
              context="overflow"
              invitation={invitation}
              type="client"
            />
          </>
        )}
        {canViewPolicy && (
          <Styled.MoreOptionSelectOption>
            <Styled.LinkButton
              onClick={event => {
                event.preventDefault();
                const link = openPolicyLink(
                  policy.policy_id,
                  policy.policy_number || "",
                );
                window.open(link);
              }}
              href={openPolicyLink(
                policy.policy_id,
                policy.policy_number || "",
              )}
            >
              View policy details
              <Styled.MoreOptionLaunchIconButton icon="link-external" />
            </Styled.LinkButton>
          </Styled.MoreOptionSelectOption>
        )}
      </Styled.MoreOptionSelectMenu>
    </Popover>
  );
};

export default InvitationsTab;
