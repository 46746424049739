import { useParams } from "react-router-dom";
import { Header, Section } from "../../../components/layout";
import {
  ApptivoLink,
  Editable,
  GoogleMapsLink,
  RecordMissing,
} from "../../../components/content";
import { commaSeparated, hasAdminLevelPriviledge } from "../../../helpers";
import {
  emailExistSchema,
  fieldRequiredSchema,
  nameSchema,
  phoneNumberSchema,
} from "../../../validations/fields.schemas";
import {
  CircularProgress,
  Link,
  TableRow,
  TableCell,
  Typography,
  TableRowSubhead,
} from "../../../components/util";
import AdminService, { IUser } from "../../../services/admin/admin.service";
import { useContext } from "react";
import { AuthContext } from "../../../contexts";
import industriesService, {
  Industry,
} from "../../../services/industries/industries.service";
import * as Styled from "./styles.css";
import { capitalizeFirstLetter } from "../../../validations/utilities";
import { useOrgQuery } from "../../../hooks/queries/useOrgQuery";

const General = () => {
  const auth = useContext(AuthContext);
  const { orgId } = useParams<IParams>();

  const {
    isLoading,
    isError,
    data: org,
    refetch: refetchOrgDetails,
  } = useOrgQuery(orgId);

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || org == null) {
    return (
      <RecordMissing
        icon="organization"
        message="Please check the organisation id or navigate back to Organisations."
        type="organisation"
        back={{
          title: "Back to Organisations",
          path: "/organisations",
        }}
      />
    );
  }

  const pid = org.externalMappings?.find(
    mapping => mapping.name === "policy" && mapping.external_id.endsWith("BPK"),
  )
    ? process.env.REACT_APP_EVARI_BPK_ID
    : process.env.REACT_APP_EVARI_CPX_ID;

  const evariId = org.derived?.evariId?.replace(/^TEAM_(\d.*)/i, "$1");

  const evariIdLink = org.derived?.evariId && (
    <Link
      href={`${process.env.REACT_APP_SALESFORCE_QUOTES_URL}/organisations/${evariId}?pid=${pid}`}
    >
      {org.derived?.evariId}
    </Link>
  );

  return (
    <Styled.Grid>
      <Section>
        <Header title="Organisation" type="section" />
        <Styled.Table
          columns={[
            { title: "Key", expand: true },
            { title: "Value", expand: true },
          ]}
          orientation="horizontal"
        >
          <TableRowSubhead>Organisation details</TableRowSubhead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>
              <Styled.OrgTypeChip
                label={capitalizeFirstLetter(org.type)}
                size="md"
                color="positive"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Organisation name</TableCell>
            <TableCell>
              <Editable
                field="name"
                fieldValue={org.name}
                hasPermission={hasAdminLevelPriviledge(auth.user?.roles || [])}
                record={org}
                recordType={`Organisation ${org.name} successfully`}
                onSubmitService={AdminService.updateOrgaisation}
                onSubmitRefresh={refetchOrgDetails}
                validationSchema={fieldRequiredSchema("Org name")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ABN</TableCell>
            <TableCell>
              <Editable
                field="abn"
                fieldValue={org.abn}
                hasPermission={hasAdminLevelPriviledge(auth.user?.roles || [])}
                record={org}
                recordType={`Organisation ${org.name} successfully`}
                onSubmitService={AdminService.updateOrgaisation}
                onSubmitRefresh={refetchOrgDetails}
                validationSchema={fieldRequiredSchema("ABN")}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Industry</TableCell>
            <TableCell>
              <Editable
                field="industry_id"
                fieldValue={org?.industry?.name}
                getList={industriesService.getAllIndustries}
                listPruner={industrySelectPruner}
                hasPermission={hasAdminLevelPriviledge(auth.user?.roles || [])}
                record={org}
                recordType={`Organisation ${org.name} successfully`}
                onSubmitService={AdminService.updateOrgaisation}
                onSubmitRefresh={refetchOrgDetails}
                validationSchema={fieldRequiredSchema("Industry")}
              />
            </TableCell>
          </TableRow>

          <TableRowSubhead>Contact details</TableRowSubhead>

          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>
              <Editable
                field="email"
                fieldValue={
                  <Link href={`mailto:${org.email}`}>{org.email}</Link>
                }
                hasPermission={hasAdminLevelPriviledge(auth.user?.roles || [])}
                onSubmitService={AdminService.updateOrgaisation}
                onSubmitRefresh={refetchOrgDetails}
                record={org}
                recordType={`Organisation ${org.name} successfully`}
                validationSchema={emailExistSchema}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone number</TableCell>
            <TableCell>
              <Editable
                field="phone"
                fieldValue={<Link href={`tel:${org.phone}`}>{org.phone}</Link>}
                hasPermission={hasAdminLevelPriviledge(auth.user?.roles || [])}
                onSubmitService={AdminService.updateOrgaisation}
                onSubmitRefresh={refetchOrgDetails}
                record={org}
                recordType={`Organisation ${org.name} successfully`}
                validationSchema={phoneNumberSchema}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <Styled.AddressCell>Address </Styled.AddressCell>
            <Styled.AddressCell>
              <Editable
                address
                fieldValue={
                  <GoogleMapsLink
                    address={commaSeparated([
                      org.address_1,
                      org.address_2,
                      org.city,
                      org.state,
                      org.post_code,
                      org.country,
                    ])}
                  />
                }
                field="address"
                hasPermission={hasAdminLevelPriviledge(auth.user?.roles || [])}
                onSubmitService={AdminService.updateOrgaisation}
                onSubmitRefresh={refetchOrgDetails}
                record={org}
                recordType={`Organisation ${org.name} successfully`}
                validationSchema={nameSchema}
              />
            </Styled.AddressCell>
          </TableRow>
        </Styled.Table>
      </Section>

      <Section>
        <Header title="Connections" type="section" />
        <Styled.Table
          columns={[
            { title: "Key", expand: true },
            { title: "Value", expand: true },
          ]}
          orientation="horizontal"
        >
          <TableRow>
            <TableCell>Evari Team ID</TableCell>
            <TableCell>
              <Editable
                field="evari"
                fieldValue={evariIdLink}
                hasPermission={hasAdminLevelPriviledge(auth.user?.roles || [])}
                record={{ ...org, evari: org.derived?.evariId || "" }}
                recordType={`Organisation ${org.name} successfully`}
                onSubmitService={AdminService.updateOrgaisation}
                onSubmitRefresh={refetchOrgDetails}
                validationSchema={fieldRequiredSchema("Evari Team ID")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Policy Number</TableCell>
            <TableCell>
              <Editable
                field="policy"
                fieldValue={org.derived?.policyNumber}
                hasPermission={hasAdminLevelPriviledge(auth.user?.roles || [])}
                record={{ ...org, policy: org.derived?.policyNumber || "" }}
                recordType={`Organisation ${org.name} successfully`}
                onSubmitService={AdminService.updateOrgaisation}
                onSubmitRefresh={refetchOrgDetails}
                validationSchema={fieldRequiredSchema("Policy Number")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>CRM ID</TableCell>
            <TableCell>
              <ApptivoLink crmId={org.crm_id} />
            </TableCell>
          </TableRow>
        </Styled.Table>
      </Section>

      <Section>
        <Header title="Organisation Users" type="section" />
        <Users orgName={org.name} users={org.users} />
      </Section>
    </Styled.Grid>
  );
};

function Users(props: IUsersProps) {
  const { orgName, users } = props;

  if (users?.length === 0) {
    return (
      <Typography variant="subtitle1">
        There are no users for this organisation
      </Typography>
    );
  }

  return (
    <Styled.Table
      columns={[
        { title: "User name", expand: true },
        { title: "User email", expand: true },
        { title: "CRM ID (Apptivo)", expand: true },
        { title: "Status", expand: true },
      ]}
    >
      {users?.map(user => (
        <TableRow key={user?.id}>
          <TableCell>
            <Link
              href={`/users/${user?.id}?backText=${encodeURIComponent(
                orgName,
              )}`}
            >
              {commaSeparated([user.lastname, user.firstname])}
            </Link>
          </TableCell>
          <TableCell>
            <Link href={`mailto:${user.email}`}>{user.email}</Link>
          </TableCell>
          <TableCell>{user.crm_id}</TableCell>
          <TableCell>{user.is_active ? "Active" : "Inactive"}</TableCell>
        </TableRow>
      ))}
    </Styled.Table>
  );
}

interface IParams {
  orgId: string; // uuid
}

interface IUsersProps {
  orgName: string;
  users: IUser[] | undefined;
}

export default General;

export const industrySelectPruner: any = (industry: Industry[]) => {
  const result = industry.map((industry: Industry) => {
    return { title: industry.name, value: industry.id };
  });
  return result;
};
