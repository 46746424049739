import {
  FormLabel,
  Input,
  InputProps,
  Typography,
} from "@safetyculture/sc-web-ui";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { FormSpacing } from "./form.css";

type Props<T extends FieldValues> = Omit<InputProps, "name"> & {
  name: keyof T; // Only map to first level
  control: Control<T>;
  rules?: Omit<
    RegisterOptions<T>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  label?: string;
  /**
   * @default {top: 24}
   */
  margin?: { top?: number; bottom?: number; left?: number; right?: number };
};

export function InputWithValidation<T extends FieldValues>({
  name,
  control,
  defaultValue,
  rules,
  label,
  margin = { top: 24 },
  ...rest
}: Props<T>) {
  const field_name = name as Path<T>;

  return (
    <FormSpacing
      $marginTop={margin.top}
      $marginBottom={margin.bottom}
      $marginLeft={margin.left}
      $marginRight={margin.right}
    >
      <Controller
        name={field_name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <FormLabel
              htmlFor={`input-${field_name}`}
              type={rules?.required ? "required" : "none"}
            >
              <Typography variant="titleSmall">{label}</Typography>
            </FormLabel>
            <FormSpacing $marginTop={4} $marginBottom={4}>
              <Input
                {...field}
                {...rest}
                id={`input-${field_name}`}
                error={!!fieldState.error}
              />
              {fieldState.error?.message && (
                <Typography
                  color="negative.text.default"
                  component="span"
                  variant="bodySmall"
                >
                  {fieldState.error.message}
                </Typography>
              )}
            </FormSpacing>
          </>
        )}
      />
    </FormSpacing>
  );
}
