import { useContext } from "react";

import {
  Form,
  FormRow,
  Grid,
  TextField,
  Typography,
} from "../../components/util";

import AuthContext from "../../contexts/authContext";
import authService from "../../services/auth/auth.service";
import { fieldRequiredSchema } from "../../validations/fields.schemas";
import { FormHeader } from "./styles.css";

const PersonalInfoCard = () => {
  let { user, ...auth } = useContext(AuthContext);

  const PersonalInfoErrorsInit: Record<string, boolean> = {};

  if (!user?.phone) {
    PersonalInfoErrorsInit.phone = true;
  }

  const PersonalInfoDataInit: Record<string, string> = {
    firstName: user?.firstname || "",
    lastName: user?.lastname || "",
    phone: user?.phone || "",
    email: user?.email || "",
  };

  const handleSubmit = async (formData: iPersonalInformation) => {
    try {
      if (user) {
        await authService.updateUser(user, formData);
      }
    } catch (error: any) {
      throw Error(error.message);
    }
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12} md={12}>
        <FormHeader>
          <Typography variant="heading">Personal information</Typography>
        </FormHeader>
        <Form
          enableSubmitAlways
          fullWidth={false}
          onSubmit={{
            service: handleSubmit,
            onSuccess: () => auth.refreshUser(),
            onSuccessMsg: "Profile updated",
          }}
          validationErrors={[PersonalInfoErrorsInit]}
          validationFields={[PersonalInfoDataInit]}
          submitInline
          submitAlign="right"
          submitText="Update profile"
        >
          <FormRow container>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First name"
                id="firstName"
                validationOnBlur
                validationType={fieldRequiredSchema("First name")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last name"
                id="lastName"
                validationOnBlur
                validationType={fieldRequiredSchema("Last name")}
              />
            </Grid>
          </FormRow>
          <FormRow container>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone number"
                type="tel"
                id="phone"
                validationOnBlur
                validationOnChange
                validationType={fieldRequiredSchema("Phone number")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Email address"
                id="email"
                validationOnBlur
                validationType={fieldRequiredSchema("Email")}
              />
            </Grid>
          </FormRow>
        </Form>
      </Grid>
    </Grid>
  );
};

export default PersonalInfoCard;

interface iPersonalInformation {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}
