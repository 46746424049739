import { IUserRolesAccess } from "../routes/routes";
import {
  tUserRole,
  tUserRoleAllowed,
  tUserRolePrivate,
  tUserRolePublic,
} from "../types";

export const userRolesPublic: tUserRolePublic[] = [
  "broker",
  "customer",
  "unverified",
];
export const userRolesPrivate: tUserRolePrivate[] = ["admin", "super_admin"];
const privlidgeOrder: tUserRole[] = [
  "unverified",
  "super_admin",
  "admin",
  "broker",
  "customer",
];

export const hasAllowedRoles = (
  inputRoles?: tUserRoleAllowed[],
  roles?: IUserRolesAccess,
) => {
  if (!inputRoles || inputRoles.length === 0) {
    return false;
  }
  if (roles?.disallowed) {
    for (const req of roles.disallowed) {
      if (inputRoles?.includes(req)) {
        return false;
      }
    }
  }
  if (roles?.allowed) {
    for (const req of roles.allowed) {
      if (req === "*") {
        // Allow for all roles
        return true;
      }
      if (inputRoles?.includes(req)) {
        return true;
      }
    }
  }
  return false;
};

export const getMostPrivlidgedRole = (inputRoles: tUserRole[]): tUserRole => {
  if (!inputRoles) {
    inputRoles = ["customer"];
  }
  return inputRoles.sort((a, b) => {
    return privlidgeOrder.indexOf(a) - privlidgeOrder.indexOf(b);
  })[0];
};

export const hasMorePrivilege = (
  inputRole: tUserRole,
  targetRole: tUserRole,
): boolean => {
  const comparison =
    privlidgeOrder.indexOf(targetRole) - privlidgeOrder.indexOf(inputRole);
  return comparison > 0 ? true : false;
};

export const hasSufficientPrivilege = (
  minimumRole: tUserRole,
  inputRoles: tUserRole[] = [],
  targetRoles: tUserRole[] = [],
) => {
  const highestUserRole = getMostPrivlidgedRole(inputRoles);
  if (highestUserRole === "super_admin") {
    return true;
  } else if (hasAllowedRoles(inputRoles, { allowed: [minimumRole] })) {
    const highestTargetRole = getMostPrivlidgedRole(targetRoles);
    return hasMorePrivilege(highestUserRole, highestTargetRole);
  }
  return false;
};

export const hasAdminLevelPriviledge = (inputRoles: tUserRole[]) => {
  return hasAllowedRoles(inputRoles, { allowed: ["admin", "super_admin"] });
};
