export function commaSeparated(values: (string | null | undefined)[]) {
  return values
    .filter(value => value != null && !value.match(/^\s*$/))
    .map(value => value?.trim())
    .join(", ");
}

export function capitiliseFirsrtChar(value: string) {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : "";
}

export const isJsonEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const isValidEmail = (email?: string | null) =>
  email != null && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email);

export function arrayify<T>(value: T | T[] | null | undefined): T[] {
  return value != null
    ? Array.isArray(value)
      ? (value as T[])
      : ([value] as T[])
    : ([] as T[]);
}

export { handleInputValue } from "./inputs";

export {
  convertDateTime,
  createDateTime,
  dateTimeCodeByFormat,
  formatDateTime,
} from "./dateTime";

export { containsHTML } from "./strings";

export { getAdjacentKey, getObjectByString } from "./objects";

export {
  getMostPrivlidgedRole,
  hasAllowedRoles,
  hasMorePrivilege,
  hasSufficientPrivilege,
  hasAdminLevelPriviledge,
  userRolesPrivate,
  userRolesPublic,
} from "./roles";

export { transformPolicyType, invitationStatus } from "./policies";

export { buildBoxShadow } from "./styles";
