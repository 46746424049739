import { useContext } from "react";
import { Activity } from "../../../services/activity/activity.service";
import authService from "../../../services/auth/auth.service";
import AuthContext from "./../../../contexts/authContext";
import { Button, Grid, TabPanel, Tabs, Typography } from "./../../util";
import OnboardingTab from "./onboardingTab";
import * as Styled from "./styles.css";
import Toast from "../../util/toast/toast";
import toastEvent from "../../util/toast/toast.event";

const OnboardingUnverified = () => {
  const auth = useContext(AuthContext);

  const isEmailVerified = auth.user?.activity?.some(
    (entry: Activity) =>
      entry.type === "Email verified" && entry.action === "Completed",
  );

  const hasTeamId = auth.user?.organisations?.[0].externalMappings?.some(
    (entry: any) => entry.name === "evari" && !!entry.id,
  );

  return (
    <Styled.OnboardingContainer corners="rounded" outlined>
      <Styled.Header title="Complete these steps to start quoting"></Styled.Header>
      <Grid container>
        <Tabs
          id="newuser"
          orientation="vertical"
          variant="contained"
          tabs={[
            <OnboardingTab
              complete={isEmailVerified}
              icon="checklist"
              id="email"
              label="Confirm your email"
              onComplete={["disabled", "restricted"]}
            />,
            <OnboardingTab
              complete={hasTeamId}
              icon="iauditor"
              id="review"
              label="Application under review"
              onComplete={["disabled", "restricted"]}
            />,
          ]}
        >
          <TabPanel id="email">
            <Typography element="h3" paragraph variant="heading">
              Confirm your email
            </Typography>
            <Styled.PanelBodyText paragraph>
              Instructions on verifying your SafetyCulture account have been
              sent to {auth.user?.email}. This verification provides protection
              for your account data.
              <Typography>
                Didn't receive an email?{" "}
                <Button
                  color="accent"
                  variant="text"
                  onClick={() => {
                    if (auth.user?.email) {
                      authService.sendConfirmationEmail(auth.user.email);
                    } else {
                      toastEvent.error("Error: Missing email address");
                    }
                  }}
                >
                  <Styled.SyncIcon color="accent" icon="sync" />
                  Resend confirmation email
                </Button>
              </Typography>
            </Styled.PanelBodyText>
          </TabPanel>

          <TabPanel id="review">
            <Styled.PanelHeading element="h3" paragraph variant="heading">
              We're reviewing your application
            </Styled.PanelHeading>
            <Styled.PanelBodyText paragraph>
              <Typography paragraph>
                Our team are currently reviewing your application and will
                respond within 48 hours. In order to operate with SafetyCulture
                Care, all brokers must hold a valid broker agreement.
              </Typography>
              <Typography paragraph>
                Once we have confirmed you’re eligibility, you will receive a
                email notification that you’re ready to quote.
              </Typography>
            </Styled.PanelBodyText>
          </TabPanel>
        </Tabs>
      </Grid>
    </Styled.OnboardingContainer>
  );
};

export default OnboardingUnverified;
