import { useQuery, UseQueryResult } from "react-query";

import AdminService, {
  IOrganisation,
} from "../../services/admin/admin.service";

export const useOrgQuery = (
  org_id: string | null,
  custom_query_key?: string,
): UseQueryResult<IOrganisation | null | undefined> => {
  const fetcher = async () => {
    return org_id ? AdminService.fetchOrgById(org_id) : null;
  };

  return useQuery<IOrganisation | null | undefined>(
    ["use-single-org-query", org_id, custom_query_key],
    fetcher,
  );
};
