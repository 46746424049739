import { createContext, ReactNode } from "react";
import { useQuery, useQueryClient } from "react-query";
import authService, { User } from "../services/auth/auth.service";
import { CircularProgress, Grid } from "../components/util";
import { useFlagsStatus } from "@unleash/proxy-client-react";

const AuthContext = createContext<AuthContextInterface>({
  updateUser: () => {},
  refreshUser: () => {},
  removeUser: () => {},
});

export const AuthContextProvider = ({ children }: AuthContextProps) => {
  const SignedInUser = "SignedInUser";
  const { flagsReady } = useFlagsStatus();
  const queryClient = useQueryClient();

  const { isLoading, data: user } = useQuery<User>(
    SignedInUser,
    async () => authService.getMittiUser(),
    {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 1000 * 60 * 5,
    },
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser: (user: User) =>
          queryClient.setQueryData(SignedInUser, user),
        refreshUser: () => queryClient.invalidateQueries(SignedInUser),
        removeUser: () => queryClient.removeQueries(SignedInUser),
      }}
    >
      {isLoading || !flagsReady ? (
        <Grid
          container
          alignContent="center"
          justify="center"
          style={{ height: "100vh" }}
        >
          <Grid item alignContent="center" justify="center">
            <CircularProgress />{" "}
          </Grid>
        </Grid>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

interface AuthContextInterface {
  user?: User;
  updateUser: (user: User) => void;
  refreshUser: () => void;
  removeUser: () => void;
}

interface AuthContextProps {
  children: ReactNode;
}

export default AuthContext;
