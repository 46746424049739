import { useEffect, useState, useContext } from "react";
import { useQuery } from "react-query";
import { CircularProgress, Grid } from "../../../components/util";
import { ListMissing } from "../../../components/content";
import AuthContext from "../../../contexts/authContext";
import prismicService, {
  DeepdiveOverviewInterface,
  DeepdiveTyskInterface,
  DeepdiveKeyStatisticsInterface,
  DeepdiveBestPracticesInterface,
  CaseStudiesInterface,
} from "../../../services/prismic/prismic.service";
import Overview from "./overview";
import Tysk from "./tysk";
import Stats from "./stats";
import Best from "./best";
import CaseStudies from "./caseStudies";

const Deepdive = () => {
  const auth = useContext(AuthContext);
  const [overview, setOverview] =
    useState<DeepdiveOverviewInterface | undefined>();
  const [tysks, setTysks] = useState<DeepdiveTyskInterface[] | undefined>();
  const [stats, setStats] =
    useState<DeepdiveKeyStatisticsInterface[] | undefined>();
  const [best, setBest] =
    useState<DeepdiveBestPracticesInterface | undefined>();
  const [caseStudies, setCaseStudies] =
    useState<CaseStudiesInterface[] | undefined>();

  const { isLoading, isError, data } = useQuery(
    "InsightsDeepdive",
    () => prismicService.getDeepdive(auth.user?.organisations[0].industry.name),
    { retry: 0 },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setOverview(data.overview);
      setTysks(data.tysks);
      setStats(data.stats);
      setBest(data.bestPractices);
      setCaseStudies(data.caseStudies);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || data == null) {
    return (
      <ListMissing
        icon="pulse"
        message="Your industy doesn't have any information at the moment, please check back later."
        type="information"
      />
    );
  }

  return (
    <Grid container spacing={3}>
      {overview && <Overview overview={overview} />}
      {tysks && <Tysk tysks={tysks} />}
      <Grid item xs={12} lg={5}>
        <Grid container spacing={3}>
          {stats && <Stats stats={stats} />}
          {best && <Best best={best} />}
        </Grid>
      </Grid>
      {caseStudies && <CaseStudies caseStudies={caseStudies} />}
    </Grid>
  );
};

export default Deepdive;
