import { recordAnalyticsEvent } from "../../analytics";
import axios from "../../axios";
import toastEvent from "../../components/util/toast/toast.event";
import {
  IPaginatedResults,
  tInvitationStatus,
  tUserRolePublic,
} from "../../types";
import { PaginationResult } from "../../types/pagination.type";

const InvitationService = {
  async fetchById(id: string): Promise<Invitation | undefined> {
    try {
      const { data } = await axios.get(`/invitations/${id}`);

      if (data == null) return;

      return data;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchPrefillForOrg(
    id: string | null,
  ): Promise<InvitationPrefill | undefined> {
    try {
      const { data } = await axios.get(`/invitations/prefill/org/${id}`);

      return data ?? undefined;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
      throw err;
    }
  },

  async fetchPrefillEmailCcForOrg(
    id: string | null,
  ): Promise<EmailCcPrefill[] | undefined> {
    try {
      const { data } = await axios.get(
        `/invitations/prefill/org/${id}/email-cc`,
      );

      return data ?? undefined;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchPrefillForPolicy(
    id: string | null,
  ): Promise<InvitationPrefill | undefined> {
    try {
      const { data } = await axios.get(`/invitations/prefill/policy/${id}`);

      return data ?? undefined;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async fetchPrefillEmailCcForPolicy({
    policyId,
    orgId,
  }: {
    policyId: string;
    orgId: string;
  }): Promise<EmailCcPrefill[] | undefined> {
    try {
      const { data } = await axios.get(
        `/invitations/prefill/policy/${policyId}/email-cc/org/${orgId}`,
      );

      return data ?? undefined;
    } catch (err) {
      toastEvent.error("Something went wrong, try again");
    }
  },

  async getAll(
    offset: number,
    limit: number,
  ): Promise<PaginationResult<InvitationResponse[]>> {
    const response = await axios.get("/invitations/all", {
      params: {
        sort_type: "created_at",
        order_by: "desc",
        limit: `${limit}`,
        page_number: `${offset}`,
      },
    });

    return response.data as PaginationResult<InvitationResponse[]>;
  },

  async getByPolicyNumber(
    offset: number,
    limit: number,
    { policyNumber }: { policyNumber: string },
  ): Promise<PaginationResult<InvitationResponse[]>> {
    const response = await axios.get(`/invitations/policy/${policyNumber}`, {
      params: {
        sort_type: "created_at",
        order_by: "desc",
        limit,
        page_number: offset,
      },
    });

    return response.data as PaginationResult<InvitationResponse[]>;
  },

  async searchByPolicyNumber(
    searchTerm: string,
    offset: number,
    limit: number,
    { policyNumber }: { policyNumber: string },
  ): Promise<PaginationResult<InvitationResponse[]>> {
    const response = await axios.get(`/invitations/policy/${policyNumber}`, {
      params: {
        searchTerm,
        sort_type: "created_at",
        order_by: "desc",
        limit,
        page_number: offset,
      },
    });

    return response.data as PaginationResult<InvitationResponse[]>;
  },

  async getByOrgId(
    offset: number,
    limit: number,
    { id }: { id: string },
  ): Promise<PaginationResult<InvitationResponse[]>> {
    const response = await axios.get(`/invitations/organisation/${id}`, {
      params: {
        sort_type: "created_at",
        order_by: "desc",
        limit,
        page_number: offset,
      },
    });

    return response.data as PaginationResult<InvitationResponse[]>;
  },

  async searchByOrgId(
    searchTerm: string,
    offset: number,
    limit: number,
    { id }: { id: string },
  ): Promise<PaginationResult<InvitationResponse[]>> {
    const response = await axios.get(`/invitations/organisation/${id}`, {
      params: {
        sort_type: "created_at",
        order_by: "desc",
        limit,
        page_number: offset,
        searchTerm,
      },
    });

    return response.data as PaginationResult<InvitationResponse[]>;
  },

  async search(
    searchTerm: string,
    offset: number,
    limit: number,
  ): Promise<PaginationResult<InvitationResponse[]>> {
    const response = await axios.get("/invitations/all", {
      params: {
        searchTerm,
        sort_type: "created_at",
        order_by: "desc",
        limit: `${limit}`,
        page_number: `${offset}`,
      },
    });

    return response.data as PaginationResult<InvitationResponse[]>;
  },

  async createInvitation(
    invitation: Omit<Invitation, "status">,
  ): Promise<boolean | undefined> {
    try {
      await axios.post("/invitations/create", {
        firstname: invitation.firstname || "",
        lastname: invitation.lastname || "",
        email: invitation.email || "",
        policy_number: invitation.policy_number,
        customer_id: invitation.customer_id || "",
        customer_org_name: invitation.customer_org_name,
        sender_org_id: invitation.sender_org_id || "",
        role: invitation.role,
        data: invitation.data,
        sender_first_name: invitation.sender_first_name,
        sender_last_name: invitation.sender_last_name,
        email_cc: invitation.email_cc,
        email_bcc: invitation.email_bcc,
      });

      recordAnalyticsEvent("action", "Portfolio Invite Client", {
        action: "client_invited",
        ...invitation,
      });

      toastEvent.success(
        `Invitation sent to ${invitation.firstname} ${invitation.lastname}`,
      );

      return true;
    } catch (err: any) {
      if (err.response?.data?.error) {
        const { data } = err.response;

        toastEvent.error(`${data.error.title}: ${data.error.detail}`);
      } else {
        toastEvent.error(`Something went wrong, try again`);
      }
    }
  },

  async resendInvitation(invitation: Invitation): Promise<boolean | undefined> {
    try {
      await axios.patch("/invitations/resend", invitation);
      recordAnalyticsEvent("action", "Invite Client Resent", {
        action: "invite_resent",
        id: invitation.id || "",
        policy_number: invitation.policy_number || "",
        customer_id: invitation.customer_id || "",
        sender_org_id: invitation.sender_org_id || "",
        role: invitation.role,
      });
      toastEvent.success(
        `Invitation resent to ${invitation.firstname} ${invitation.lastname}`,
      );

      return true;
    } catch (err: any) {
      if (err.response?.data?.error) {
        const { data } = err.response;

        toastEvent.error(`${data.error.title}: ${data.error.detail}`);
      } else {
        toastEvent.error(`Something went wrong, try again`);
      }
    }
  },

  async cancelInvitation(invitation: Invitation): Promise<Boolean> {
    try {
      await axios.patch("/invitations/cancel", {
        id: invitation.id || "",
      });
      toastEvent.success(
        `Invitation cancelled for ${invitation.firstname} ${invitation.lastname}`,
      );
      recordAnalyticsEvent("action", "Invite Client Cancelled", {
        action: "invite_cancelled",
        id: invitation.id || "",
        policy_number: invitation.policy_number || "",
        customer_id: invitation.customer_id || "",
        sender_org_id: invitation.sender_org_id || "",
      });
      return true;
    } catch (err) {
      toastEvent.error(`Something went wrong, try again`);
      return false;
    }
  },

  async getInvitationStatus(
    id: string,
  ): Promise<tInvitationStatus | undefined> {
    try {
      const { data } = await axios.get(`/invitations/${id}/status`);
      return data;
    } catch (err) {}
  },
};

export interface Invitation {
  claimed?: string; // ISO-8601
  id?: string;
  status: tInvitationStatus;
  firstname?: string;
  lastname?: string;
  email?: string;
  policy_number?: string;
  customer_id?: string;
  customer_org_name?: string;
  role: tUserRolePublic;
  sender_org_id?: string;
  sender_first_name?: string;
  sender_last_name?: string;
  email_cc?: string[];
  email_bcc?: string[];
  sender_org_name?: string;
  created_at?: string;
  updated_at?: string;
  data?: any;
  policy_id?: string; // uuid
  org_name?: string;
  sender?: {
    firstname: string;
    lastname: string;
    email: string;
  };
}

export interface InvitationResponse
  extends Omit<Invitation, "policy_id" | "scheme"> {
  id: string; // uuid
  status: tInvitationStatus;
  email: string;
  created_at: string; // iso-8601
  sender?: Invitation["sender"];
  data: { scheme?: string };
}

export interface InvitationPaginatedResults
  extends Omit<IPaginatedResults, "hits"> {
  hits: InvitationResponse[];
}

export type InvitationPrefill = {
  fields: {
    firstname?: string;
    lastname?: string;
    email?: string;
    policy_number?: string;
    toolkit_code?: string;
    role: tUserRolePublic;
    sender_org_id?: string;
    sender_first_name?: string;
    sender_last_name?: string;
    email_cc?: string[];
    email_bcc?: string[];
    customer_org_name?: string;
  };
  meta: {
    sender_org_name: string;
    email_cc?: EmailCcPrefill[];
    invalid_toolkit_code?: string;
  };
};

export type EmailCcPrefill = {
  name?: string;
  email: string;
};

export default InvitationService;
