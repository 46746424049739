import { useContext } from "react";
import { AuthContext } from "../../../../contexts";
import { CircularProgress, Select } from "../../../util";
import { uuidSchema } from "../../../../validations";
import { useQuery } from "react-query";
import AdminService from "../../../../services/admin/admin.service";
import { User } from "../../../../services/auth/auth.service";
import { tUserRole } from "../../../../types";
import { hasAllowedRoles, hasMorePrivilege } from "../../../../helpers";

const SelectUserRole = ({
  currentUser,
  disabled,
  id,
  label,
  value,
  ...otherProps
}: iSelectUserRoleProps) => {
  const { user } = useContext(AuthContext);
  const {
    isLoading,
    isError,
    data: roles,
  } = useQuery("user-roles", AdminService.fetchAllRoles);

  const roleOptions: any = (roles: any, currentUserRoles: tUserRole[]) => {
    const result = roles
      .filter((role: any) => {
        return (
          hasAllowedRoles(currentUserRoles, { allowed: ["super_admin"] }) ||
          hasMorePrivilege(currentUserRoles[0], role.name)
        );
      })
      .map((role: any) => {
        return { title: role.name, value: role.id };
      });
    return result;
  };

  if ((isLoading && !roles) || isError) {
    return <CircularProgress />;
  }

  return (
    <Select
      id={id}
      disabled={disabled}
      fullWidth
      placeholder="Select user role..."
      size={label ? "lg" : undefined}
      label={label}
      validationOnChange
      validationType={uuidSchema}
      options={roleOptions(roles, user?.roles)}
      grow
      value={value}
    />
  );
};

interface iSelectUserRoleProps {
  currentUser?: User;
  id: string;
  label?: string;
  value?: any;
  disabled?: boolean;
}

SelectUserRole.defaultProps = {};

export default SelectUserRole;
