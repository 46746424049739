import { ReactNode } from "react";
import { tUserRole, tUserRoleAllowed } from "../types";
import {
  Activity,
  AuthorizeLogin,
  AutomationListPage,
  AutomationCreatePage,
  Faqs,
  ResetPassword,
  ChangePassword,
  Insights,
  Organisations,
  OrganisationDetails,
  CreateOrganisation,
  Users,
  UserDetails,
  Policies,
  PolicyDetails,
  Invitations,
  InvitePolicyHolderToSC,
  InviteBrokerToCare,
  InvitationDetails,
  InvitationCancelled,
  InvitationClaimed,
  InvitationExpired,
  Assessment,
  Portfolio,
  Profile,
  Logout,
  Login,
  SignUp,
  CreateQuote,
  CreateClaim,
  ClaimDetails,
  Claims,
  Unverified,
  Toolkits,
  ToolkitEdit,
  Toolkit,
} from "../pages";
import InvitationEmail from "../pages/auth/errors/invitationEmail";
import AlreadyLoggedIn from "../pages/auth/newUser/signUp/alreadyLoggedIn";
import { AutomationDetailsPage } from "../pages/automations/AutomationDetailsPage";
import { HomeRedirect } from "./HomeRedirect";

/**
 * title: Page Title
 * path: page url path
 * component: The component to render
 * requiredRoles: required roles for a user to access page and appear in sidebar
 *                Admin can see and access everything
 */

const routes: RouteInterface[] = [
  {
    title: "",
    path: "/",
    component: <HomeRedirect />,
    roles: {
      allowed: ["unverified", "customer", "broker", "admin", "super_admin"],
    },
  },
  {
    title: "Insights",
    path: "/risk-and-safety-insights",
    component: <Insights />,
    roles: {
      allowed: ["broker"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Organisation Details",
    path: "/organisations/:orgId",
    component: <OrganisationDetails />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Users",
    path: "/users",
    component: <Users />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "User Details",
    path: "/users/:id",
    component: <UserDetails />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Organisations",
    path: "/organisations",
    component: <Organisations />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Organisations",
    path: "/organisation/create",
    component: <CreateOrganisation />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Policies",
    path: "/policies",
    component: <Policies />,
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Activity",
    path: "/activity",
    component: <Activity />,
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Invitations",
    path: "/invitations",
    component: <Invitations />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Invite broker",
    path: "/invitations/broker/invite",
    component: <InviteBrokerToCare />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Invite policy holder to SafetyCulture",
    path: "/invitations/policy-holder/invite",
    component: <InvitePolicyHolderToSC />,
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Invitations",
    path: "/invitations/:id",
    component: <InvitationDetails />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Toolkits",
    path: "/toolkits",
    component: <Toolkits />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Create Toolkit",
    path: "/toolkits/create",
    component: <ToolkitEdit />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Edit Toolkit",
    path: "/toolkits/:id/edit",
    component: <ToolkitEdit />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Toolkit",
    path: "/toolkits/:id",
    component: <Toolkit />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Toolkit",
    path: "/toolkits/code/:code",
    component: <Toolkit />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Policy Details",
    path: "/policies/:policy_id",
    component: <PolicyDetails />,
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Virtual survey",
    path: "/book-assessment",
    component: <Assessment />,
    roles: {
      allowed: ["customer"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Portfolio view",
    path: "/portfolio",
    component: <Portfolio />,
    roles: {
      allowed: ["broker"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "New Quote",
    path: "/quotes/create",
    component: <CreateQuote />,
    pageType: "flow",
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Claims",
    path: "/claims",
    component: <Claims />,
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Create Claim",
    path: "/claims/create",
    component: <CreateClaim />,
    pageType: "flow",
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Claims Details",
    path: "/claims/:claimId",
    component: <ClaimDetails />,
    roles: {
      allowed: ["broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "FAQs",
    path: "/faqs",
    component: <Faqs />,
    roles: {
      allowed: ["customer"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Profile",
    path: "/profile",
    component: <Profile />,
    roles: {
      allowed: ["customer", "broker", "admin", "super_admin"],
      disallowed: ["unverified"],
    },
  },
  {
    title: "Log out",
    path: "/logout",
    component: <Logout />,
  },

  {
    title: "Unverified",
    path: "/get-started",
    pathRedirect: {
      unverified: "/get-started",
      customer: "/",
      broker: "/portfolio",
      admin: "/users",
      super_admin: "/users",
    },
    component: <Unverified />,
    roles: {
      allowed: ["unverified"],
    },
  },

  {
    title: "Already logged in",
    path: "/already-logged-in",
    component: <AlreadyLoggedIn />,
  },

  //----------- AUTH --------------
  {
    title: "Authorise Login",
    path: "/authorize/login",
    component: <AuthorizeLogin />,
  },
  {
    title: "Login",
    path: "/login",
    component: <Login />,
  },
  {
    title: "Sign up",
    path: "/sign-up",
    component: <SignUp />,
  },
  {
    title: "Reset password",
    path: "/password/reset",
    component: <ResetPassword />,
  },
  {
    title: "Change password",
    path: "/password/change",
    component: <ChangePassword />,
  },
  {
    title: "Invitation cancelled",
    path: "/auth/invitation-cancelled",
    component: <InvitationCancelled />,
  },
  {
    title: "Invitation claimed",
    path: "/auth/invitation-claimed",
    component: <InvitationClaimed />,
  },
  {
    title: "Invitation expired",
    path: "/auth/invitation-expired",
    component: <InvitationExpired />,
  },
  {
    title: "Your currently logged",
    path: "/auth/invitation-email",
    component: <InvitationEmail />,
  },
  {
    title: "Automations",
    path: "/automations",
    component: <AutomationListPage />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
    featureFlag: "portal.automations.create",
  },
  {
    title: "Automations",
    path: "/automations/create",
    component: <AutomationCreatePage />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
    featureFlag: "portal.automations.create",
  },
  {
    title: "Automations",
    path: "/automations/:automation_id",
    component: <AutomationDetailsPage />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
    featureFlag: "portal.automations.create",
  },
  {
    title: "Automations",
    path: "/automations/:automation_id/edit",
    component: <AutomationDetailsPage />,
    roles: {
      allowed: ["admin", "super_admin"],
      disallowed: ["unverified"],
    },
    featureFlag: "portal.automations.create",
  },
];

export interface RouteInterface {
  title: string;
  path: string;
  pathRedirect?: Record<tUserRole, string>;
  component: ReactNode;
  roles?: IUserRolesAccess;
  pageType?: "flow";
  featureFlag?: string;
}

export interface IUserRolesAccess {
  allowed?: tUserRoleAllowed[];
  disallowed?: tUserRoleAllowed[];
}

export default routes;
