import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { IPolicy } from "../../../types";
import { RecordMissing } from "../../../components/content";
import { CircularProgress } from "../../../components/util";
import * as Styled from "./styles.css";
import PolicyService from "../../../services/policy/policy.service";
import Bpk from "./bpk";
import Cpx from "./cpx";
import { AuthContext, GuidanceContext } from "../../../contexts";
import { useContext, useEffect } from "react";
import useLocalStorageState from "use-local-storage-state";
import { usePolicyQuery } from "../../../hooks/queries/usePolicyQuery";

const PolicyDetails = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { policy_id } = useParams<IPolicyPageParams>();
  const policy_query = usePolicyQuery(policy_id);
  const search_query = queryString.parse(location.search);
  const backText = search_query?.backText as string;
  const { setNewBrokerClientProgress } = useContext(GuidanceContext);

  const [BCVisited, setBCVisited] = useLocalStorageState(
    `${user?.id}-BCVisited`,
    true,
  );

  useEffect(() => {
    setBCVisited(true);
    setNewBrokerClientProgress(false);
  }, [BCVisited, setBCVisited, setNewBrokerClientProgress]);

  if (policy_query.isLoading) {
    return <CircularProgress contained />;
  } else if (policy_query.isError || policy_query.data == null) {
    return (
      <RecordMissing
        icon="file"
        message="Please check the policy number or navigate back to your policies."
        type="policy"
        back={{
          title: "Back to Policies",
          path: "/policies",
        }}
      />
    );
  }

  return (
    <Styled.Grid>
      {policy_query.data?.type === "bpk" || policy_query.data?.type === "wc" ? (
        <Bpk policy={policy_query.data} back_text={backText} />
      ) : (
        <></>
      )}
      {policy_query.data?.type === "cpx" ? (
        <Cpx policy={policy_query.data} back_text={backText} />
      ) : (
        <></>
      )}
    </Styled.Grid>
  );
};

interface IPolicyPageParams {
  policy_id: string;
}

export default PolicyDetails;
