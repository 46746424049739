import axios from "../../axios";
import toastEvent from "../../components/util/toast/toast.event";
import { IPaginatedResults, IPolicy } from "../../types";

const PolicyService = {
  async policyOrgPivot(action: string, data: any): Promise<boolean> {
    try {
      await axios.post(`/catalog/policies/org/${action}`, data);
      toastEvent.success(
        `Organisation ${action.toLocaleLowerCase()} successfully.`,
      );
      return true;
    } catch (err) {
      toastEvent.error("Failed to add Organisation from Policy.");
      throw err;
    }
  },

  async get_policies_for_user(
    offset: number,
    limit: number,
    dataParams: any | null | undefined,
  ): Promise<IPolicyPaginatedResults> {
    const org_id = dataParams?.org_id;

    let params = new URLSearchParams({
      offset: `${offset}`,
      limit: `${limit}`,
    });

    const API_SUB = org_id
      ? `/catalog/policies/organisation/${org_id}`
      : `/catalog/policies/`;

    const policies = await axios.get(API_SUB, {
      params: params,
    });

    return policies.data as IPolicyPaginatedResults;
  },

  async search_policies(
    searchTerm: string,
    offset: number,
    limit: number,
    dataParams: any,
  ): Promise<IPolicyPaginatedResults> {
    let params = new URLSearchParams({
      searchTerm,
      offset: `${offset}`,
      limit: `${limit}`,
    });

    const org_id = dataParams?.org_id;

    const types: string[] | null | undefined = dataParams?.types;

    if (types && Array.isArray(types)) {
      for (let t of types) {
        params.append("types[]", t);
      }
    }

    const API_SUB = org_id
      ? `/catalog/policies/organisation/search/${org_id}`
      : `/catalog/policies/search`;

    const policies = await axios.get(API_SUB, {
      params: params,
    });

    return policies.data as IPolicyPaginatedResults;
  },

  async get_policy_by_id(id: string): Promise<IPolicy> {
    const policy = await axios.get(`/catalog/policies/${id}`);

    return policy.data as IPolicy;
  },

  async patchSettings(id: string, settings: PolicySettings): Promise<void> {
    try {
      await axios.patch(`/catalog/policies/${id}/settings`, settings);
      toastEvent.success("Saved successfully");
    } catch (err) {
      toastEvent.error("Error: failed to save");
      throw err;
    }
  },
};

export interface IPolicyPaginatedResults
  extends Omit<IPaginatedResults, "hits"> {
  hits: IPolicy[];
}

export type PolicySettings = {
  do_not_invite: boolean;
};

export default PolicyService;
