import { useContext, useState } from "react";
import { InviteGrid } from "../styles.css";
import { Header, Section } from "../../../components/layout";
import { BrokerInviteForm } from "./BrokerInviteForm";
import InvitationService, {
  Invitation,
  InvitationPrefill,
} from "../../../services/invitation/invitation.service";
import { useHistory, useLocation } from "react-router-dom";
import { BrokerInvitationFields } from "./BrokerInvitationFields.models";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import { Spinner } from "@safetyculture/sc-web-ui";
import { Spacing } from "../../../components/layout/Spacing";
import { EmptyState } from "../../../components/layout/EmptyState";
import { AuthContext } from "../../../contexts";

type Props = {};

export const InviteBrokerToCare: React.FC<Props> = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const org_id = query.get("id");
  const user_context = useContext(AuthContext);

  const [invite_data_prefill, setInviteDataPrefill] =
    useState<InvitationPrefill | undefined | null>(undefined);

  useAsyncEffect(async () => {
    if (org_id) {
      const invite_prefill = await InvitationService.fetchPrefillForOrg(org_id);
      setInviteDataPrefill(invite_prefill);
    } else {
      setInviteDataPrefill(null);
    }
  }, [org_id]);

  const handleSubmit = async (fields: BrokerInvitationFields) => {
    const invite: Pick<
      Invitation,
      | "firstname"
      | "lastname"
      | "email"
      | "role"
      | "sender_org_id"
      | "sender_first_name"
      | "sender_last_name"
    > = {
      email: fields.email,
      firstname: fields.firstname,
      lastname: fields.lastname,
      sender_first_name: user_context.user?.firstname,
      sender_last_name: user_context.user?.lastname,
      role: "broker", // Always "broker" for a broker invite
      sender_org_id: org_id || undefined,
    };

    const created = await InvitationService.createInvitation(invite);
    if (created) {
      history.push("/invitations");
    }
  };

  return (
    <InviteGrid>
      <Header
        title={`Invite broker${
          invite_data_prefill?.meta?.sender_org_name
            ? ` to ${invite_data_prefill?.meta?.sender_org_name}`
            : ""
        }`}
        titleIcon="mail"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/users", text: "All users" }}
      />
      <Section>
        {invite_data_prefill === undefined && (
          <>
            <Spacing $height={200} />
            <Spinner />
          </>
        )}
        {(!!invite_data_prefill || org_id === null) && (
          <>
            <Spacing $height={32} />
            <BrokerInviteForm
              onSubmit={handleSubmit}
              invite_prefill={invite_data_prefill}
            />
            <Spacing $height={32} />
          </>
        )}
        {invite_data_prefill === null && org_id !== null && (
          <EmptyState title="Wrong link. No organisations found" />
        )}
      </Section>
    </InviteGrid>
  );
};
