import * as React from "react";
import { useParams } from "react-router-dom";
import { useAutomationQuery } from "../../../hooks/queries/useAutomationQuery";
import * as Styled from "../styles.css";
import { Header } from "../../../components/layout";
import { AutomationStatusChip } from "../AutomationStatusChip";
import { Summary } from "../../../components/content";
import { formatDateTime, hasAdminLevelPriviledge } from "../../../helpers";
import { AutomationDetailsTabs } from "./AutomationDetailsTabs";
import { Button } from "../../../components/util";
import { AuthContext } from "../../../contexts";

export const AutomationDetailsPage: React.FC = () => {
  const { automation_id } = useParams<{ automation_id: string }>();
  const automation = useAutomationQuery(automation_id);
  const user = React.useContext(AuthContext)?.user;

  const is_privileged_user = hasAdminLevelPriviledge(user?.roles || []);

  return (
    <Styled.Grid $fullHeight>
      <Header
        title={automation.data?.name}
        titleIcon="dependabot"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/automations", text: "All Automations" }}
        actions={
          is_privileged_user ? (
            <Button
              color="surface"
              router
              href={`/automations/${automation_id}/edit`}
              variant="contained"
              fullWidth={true}
              minWidth="118px"
            >
              Edit Automation
            </Button>
          ) : undefined
        }
      />
      <Summary
        data={[
          {
            Enabled: (
              <AutomationStatusChip enabled={automation?.data?.enabled} />
            ),
          },
          {
            Created: automation?.data
              ? formatDateTime(automation.data.created_at.toString(), "verbose")
              : "",
          },
          {
            Updated: automation?.data
              ? formatDateTime(automation.data.updated_at.toString(), "verbose")
              : "",
          },
        ]}
      />
      {!!automation.data && (
        <AutomationDetailsTabs automation={automation.data} />
      )}
    </Styled.Grid>
  );
};
