import { useContext, useState } from "react";
import { InviteGrid } from "../styles.css";
import { Header, Section } from "../../../components/layout";
import { PolicyInviteForm } from "./PolicyInviteForm";
import InvitationService, {
  EmailCcPrefill,
  Invitation,
  InvitationPrefill,
} from "../../../services/invitation/invitation.service";
import { useHistory, useLocation } from "react-router-dom";
import { PolicyHolderScInvite } from "./PolicyHolderScInvite.fields";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import { Spinner } from "@safetyculture/sc-web-ui";
import { hasAllowedRoles } from "../../../helpers";
import { AuthContext } from "../../../contexts";
import { IOption } from "@safetyculture/sc-web-ui/cjs/packages/react/select/types";
import styled from "styled-components";
import { Spacing } from "../../../components/layout/Spacing";
import { usePolicyQuery } from "../../../hooks/queries/usePolicyQuery";

type Props = {};

export const InvitePolicyHolderToSC: React.FC<Props> = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const policy_id = query.get("id");
  const policy_query = usePolicyQuery(policy_id);
  const [invite_data_prefill, setInviteDataPrefill] =
    useState<InvitationPrefill | undefined | null>(undefined);
  const is_privileged_user = hasAllowedRoles(user?.roles, {
    allowed: ["admin", "super_admin"],
  });

  useAsyncEffect(async () => {
    if (policy_id) {
      try {
        const invite_prefill = await InvitationService.fetchPrefillForPolicy(
          policy_id,
        );
        setInviteDataPrefill(invite_prefill);
      } catch {
        setInviteDataPrefill(null);
      }
    }
  }, [policy_id]);

  const handleSubmit = async (fields: PolicyHolderScInvite) => {
    const invite: Omit<Invitation, "status"> = {
      firstname: fields.firstname,
      lastname: fields.lastname,
      email: fields.email,
      policy_number: invite_data_prefill?.fields.policy_number,
      customer_org_name: invite_data_prefill?.fields.customer_org_name,
      role: "customer", // Policy holders are always customer type
      data: {
        scheme: fields.toolkit_code?.value,
      },
      sender_org_id: invite_data_prefill?.fields.sender_org_id,
      sender_first_name: fields.sender_first_name,
      sender_last_name: fields.sender_last_name,
      email_cc:
        fields.email_cc?.map((o: IOption<EmailCcPrefill>) => o.value) || [],
      email_bcc:
        fields.email_bcc?.map((o: IOption<EmailCcPrefill>) => o.value) || [],
    };

    const created = await InvitationService.createInvitation(invite);
    if (created) {
      history.push(`/policies/${policy_id}`);
    }
  };

  return (
    <InviteGrid>
      <Header
        title={`Invite ${
          policy_query?.data?.policy_data?.BUSINESS_NAME ||
          policy_query?.data?.policy_data?.business_name
        } to SafetyCulture`}
        titleIcon="mail"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/policies", text: "All Policies" }}
      />
      <Section>
        {invite_data_prefill === undefined && (
          <>
            <Spacing $height={200} />
            <Spinner />
          </>
        )}
        {invite_data_prefill !== undefined && (
          <VerticallySpaced>
            <PolicyInviteForm
              is_policy_invitate_to_sc_disabled={
                policy_query.data?.do_not_invite || false
              }
              onSubmit={handleSubmit}
              invite_prefill={invite_data_prefill}
              policy_id={policy_id}
              is_privileged_user={is_privileged_user}
            />
          </VerticallySpaced>
        )}
      </Section>
    </InviteGrid>
  );
};

const VerticallySpaced = styled.div`
  margin-top: 32px;
`;
