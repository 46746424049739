import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import queryString from "query-string";
import { Header } from "../../../components/layout";
import { RecordMissing, Summary } from "../../../components/content";
import { formatDateTime, hasAllowedRoles } from "../../../helpers";
import { CircularProgress, TabPanel, Tab } from "../../../components/util";
import AdminService, {
  IOrganisation,
} from "../../../services/admin/admin.service";
import { Industry } from "../../../services/industries/industries.service";
import { capitalizeFirstLetter } from "../../../validations/utilities";
import Details from "./details";
import InvitationsTab from "./invitationsTab";
import ToolkitsTab from "./toolkitsTab";
import ParentOrgs from "./orgTables/parentOrgs";
import ChildOrgs from "./orgTables/childOrg";
import ClientList from "../../portfolio/clientList";
import * as Styled from "./styles.css";
import { AuthContext } from "../../../contexts";
import { useOrgQuery } from "../../../hooks/queries/useOrgQuery";

const OrganisationDetails = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { orgId } = useParams<IParams>();
  const adminOrganisationDetails = "admin-organisation-details";
  const [isParentOrgTabVisible, setParentOrgTabVisible] =
    useState<boolean>(false);
  const [isChildOrgTabVisible, setChildOrgTabVisible] =
    useState<boolean>(false);

  const adminVisibility = user
    ? hasAllowedRoles(user.roles, {
        allowed: ["admin", "super_admin"],
      })
    : false;

  const {
    isLoading,
    isError,
    data: org,
  } = useOrgQuery(orgId, adminOrganisationDetails);

  const [tabeOrgId] = useState<string>(orgId);

  useEffect(() => {
    setChildOrgTabVisible(false);
    setParentOrgTabVisible(false);
    if (tabeOrgId && org?.childOrgMap) setChildOrgTabVisible(true);
    if (tabeOrgId && org?.parentOrgMap) setParentOrgTabVisible(true);
  }, [
    isParentOrgTabVisible,
    isChildOrgTabVisible,
    org,
    tabeOrgId,
    location.search,
    isLoading,
  ]);

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || org == null) {
    return (
      <RecordMissing
        icon="organization"
        message="Please check the organisation id or navigate back to Organisations."
        type="organisation"
        back={{
          title: "Back to Organisations",
          path: "/organisations",
        }}
      />
    );
  }

  return (
    <Styled.Grid>
      <Header
        title={capitalizeFirstLetter(org.name)}
        titleIcon="organization"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/organisations", text: "All organisations" }}
      >
        <Hidden xsDown>
          <Styled.SubHeader element="span" variant="heading">
            {org.email}
          </Styled.SubHeader>
        </Hidden>
      </Header>
      <Summary
        data={[
          {
            Type: org.type || "",
          },
          {
            Created: formatDateTime(org.created_at || "", "verbose"),
          },
          {
            Updated: formatDateTime(org.updated_at || "", "verbose"),
          },
        ]}
      />
      <Styled.Tabs item xs={12}>
        <Styled.InnerTabs
          id="user-details"
          tabs={[
            <Tab id="details" label="Details" />,
            <Tab id="invitations" label="Invitations" />,
            <Tab
              id="parent"
              label={"Parent organisations"}
              hidden={!isParentOrgTabVisible}
            />,
            <Tab
              id="child"
              label={"Child organisations"}
              hidden={!isChildOrgTabVisible}
            />,
            <Tab id="toolkits" label="Toolkits" hidden={!adminVisibility} />,
            <Tab id="policy" label={"Policies"} hidden={!adminVisibility} />,
          ]}
          scrolling
        >
          <TabPanel id="details">
            <Details />
          </TabPanel>
          <TabPanel id="invitations">
            <InvitationsTab orgId={orgId} />
          </TabPanel>

          <Styled.STTabpanel id="parent" spaced>
            <ParentOrgs orgId={tabeOrgId} />
          </Styled.STTabpanel>

          <Styled.STTabpanel id="child" spaced>
            <ChildOrgs orgId={tabeOrgId} />
          </Styled.STTabpanel>

          <TabPanel id="toolkits">
            <ToolkitsTab orgId={orgId} />
          </TabPanel>

          <Styled.STTabpanel id="policy" spaced>
            <ClientList orgId={orgId} />
          </Styled.STTabpanel>
        </Styled.InnerTabs>
      </Styled.Tabs>
    </Styled.Grid>
  );
};

interface IParams {
  orgId: string; // uuid
}

export default OrganisationDetails;

export const industrySelectPruner: any = (industry: Industry[]) => {
  const result = industry.map((industry: Industry) => {
    return { title: industry.name, value: industry.id };
  });
  return result;
};
