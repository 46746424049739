import { useCallback, useContext, useEffect } from "react";
import { InlineWidget } from "react-calendly";
import { AuthContext } from "../../../contexts";
import CalendlyService from "../../../services/calendly/calendly.service";
import * as Styled from "./styles.css";

const VirtualSurveyWidget = ({
  email,
  name,
  organisation_id,
  user_id,
  ...otherProps
}: VirtualSurveyWidgetProps) => {
  const auth = useContext(AuthContext);
  const calendlyStyles = {
    width: "100%",
    height: "1200px",
  };

  const calendlyColors = {
    primaryColor: "675df4",
    textColor: "1d2330",
  };

  const createActivityOnBooking = useCallback(
    (event: any) => {
      if (isEventScheduled(event) && event.data?.payload) {
        CalendlyService.createBookingActivity({
          event: event.data?.payload?.event?.uri,
          booked_by: auth.user?.firstname + " " + auth.user?.lastname,
          invitee: event.data?.payload?.invitee?.uri,
          organisation_id: organisation_id,
          user_id: user_id,
        });
      }
    },
    [auth.user?.firstname, auth.user?.lastname, organisation_id, user_id],
  );

  useEffect(() => {
    window.addEventListener("message", createActivityOnBooking);
    return () => window.removeEventListener("message", createActivityOnBooking);
  }, [createActivityOnBooking]);

  return (
    <Styled.WidgetContainer>
      <InlineWidget
        frameborder="0"
        height="1200"
        // @ts-ignore
        url={process.env.REACT_APP_CALENDLY_VRA_LINK}
        scrolling="no"
        prefill={{
          email: email,
          name: name,
        }}
        styles={{ ...calendlyStyles }}
        pageSettings={{ ...calendlyColors }}
      />
    </Styled.WidgetContainer>
  );
};

function isEventScheduled(e: any) {
  return e.data.event && e.data.event.indexOf("calendly.event_scheduled") === 0;
}

export interface VirtualSurveyWidgetProps {
  email: string;
  name: string;
  organisation_id: string;
  user_id: string;
}

VirtualSurveyWidget.defaultProps = {};

export default VirtualSurveyWidget;
