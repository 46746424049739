import { useQuery, UseQueryResult } from "react-query";
import { IOption } from "@safetyculture/sc-web-ui/cjs/packages/react/select/types";
import { useContext } from "react";
import { AuthContext } from "../../../contexts";
import toolkitService, {
  Toolkit,
} from "../../../services/toolkits/toolkits.service";
import { hasAllowedRoles } from "../../../helpers";

export const useToolkitSelectOptions = (options?: {
  toolkit_search_term?: string;
  org_id?: string;
  policy_id?: string;
}): UseQueryResult<IOption<Toolkit>[]> => {
  const { user } = useContext(AuthContext);
  const is_privileged_user = hasAllowedRoles(user?.roles, {
    allowed: ["admin", "super_admin"],
  });

  const fetchToolkits = async (): Promise<IOption<Toolkit>[] | undefined> => {
    let toolkits: Toolkit[] | undefined;
    if (is_privileged_user) {
      toolkits = (await toolkitService.getAll()).hits;
    } else if (options?.org_id || options?.policy_id) {
      toolkits = await toolkitService.getToolkits(options);
    }
    if (toolkits) {
      return toolkits
        .map(t => ({
          label: t.code || "",
          value: t.code || "",
          meta: t,
        }))
        .filter(t => t.value !== "");
    }
    return [];
  };

  return useQuery({
    queryKey: [
      "toolkit_select_options",
      options?.toolkit_search_term,
      options?.policy_id,
    ],
    queryFn: fetchToolkits,
  });
};
