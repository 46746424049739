import { IconButton, TextField } from "../../../util";
import toastEvent from "../../../util/toast/toast.event";
import * as Styled from "./styles.css";

interface iEditInlineProps {
  field: any;
  onClose: () => void;
  onSubmitService: (any) => Promise<any>;
  onSubmitRefresh: () => void;
  toggleEditing: () => void;
  record: Record<string, any>;
  recordType: string;
  validationSchema: any;
}

const EditInline = ({
  field,
  onClose,
  onSubmitService,
  onSubmitRefresh,
  record,
  recordType,
  toggleEditing,
  validationSchema,
  ...otherProps
}: iEditInlineProps) => {
  const setErrors = (editDetails: any): Record<string, boolean> => {
    const errors = {};
    for (const key in editDetails) {
      if (key === field) {
        errors[key] = true;
      } else {
        errors[key] = false;
      }
    }
    return errors as Record<string, boolean>;
  };
  const editErrorsInit = setErrors(record);

  const editDataInit: Record<string, string> = {
    ...record,
  };

  const handleSubmit = async formData => {
    try {
      await onSubmitService(formData);
    } catch (error: any) {
      throw Error(error.message);
    }
  };

  return (
    <>
      <Styled.InlineForm
        enableSubmitAlways
        fullWidth={false}
        inline
        onSubmit={{
          service: handleSubmit,
          onSuccess: () => {
            onSubmitRefresh();
            toastEvent.success(`${recordType} updated`);
            toggleEditing();
          },
        }}
        validationErrors={[editErrorsInit]}
        validationFields={[editDataInit]}
        submitInline
        submitAlign="right"
        submitStartDisabled
        submitText="Save"
      >
        <TextField
          autoFocus
          fullWidth
          grow
          id={field}
          validationOnChange
          validationType={validationSchema}
        />
      </Styled.InlineForm>
      <IconButton iconShade="weak" icon="x" onClick={() => toggleEditing()} />
    </>
  );
};

export default EditInline;
