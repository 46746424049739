import { useQuery, UseQueryResult } from "react-query";
import { IPolicy } from "../../types";
import PolicyService from "../../services/policy/policy.service";

export const usePolicyQuery = (
  policy_id: string | null,
): UseQueryResult<IPolicy | null | undefined> => {
  const fetcher = async () => {
    return policy_id ? PolicyService.get_policy_by_id(policy_id) : null;
  };

  return useQuery<IPolicy | null | undefined>(
    ["use-single-policy-query", policy_id],
    fetcher,
  );
};
