import React, { useEffect } from "react";
import { Control } from "react-hook-form";
import { PolicyHolderScInvite } from "./PolicyHolderScInvite.fields";
import { FormLabel, Input, Typography } from "@safetyculture/sc-web-ui";
import { SelectWithValidation } from "../../../components/form/SelectWithValidation";
import { InputWithValidation } from "../../../components/form/InputWithValidation";
import { FormRow, InviteFormGrid } from "../styles.css";
import {
  EmailCcPrefill,
  InvitationPrefill,
} from "../../../services/invitation/invitation.service";
import { IOption } from "@safetyculture/sc-web-ui/cjs/packages/react/select/types";
import { validation_rules } from "../../../components/form/validation_rules";
import { useContactEmailSelectOptions } from "../../../hooks/queries/select_options/useContactEmailSelectOptions.query";
import { Container } from "../../../components/util";
import { Spacing } from "../../../components/layout/Spacing";

type Props = {
  control: Control<PolicyHolderScInvite>;
  is_privileged_user: boolean;
  invite_prefill?: InvitationPrefill | null;
  policy_id: string | null;
};

export const InviterInformation: React.FC<Props> = ({
  control,
  is_privileged_user,
  invite_prefill,
  policy_id,
}) => {
  // additional email options
  const [additional_email_cc_options, setAdditionalEmailCcOptions] =
    React.useState<IOption<EmailCcPrefill>[]>([]);
  const addEmailRecipient = (email_option: IOption) => {
    setAdditionalEmailCcOptions(prev =>
      prev.concat({ ...email_option, meta: { email: email_option.value } }),
    );
  };
  useEffect(() => {
    if (invite_prefill) {
      const prefill_cc =
        invite_prefill.fields.email_cc?.map((e: string) => ({
          value: e,
          label: e,
          meta: { email: e },
        })) || [];

      setAdditionalEmailCcOptions(prefill_cc);
    }
  }, [invite_prefill]);

  // email options
  const email_options = useContactEmailSelectOptions({
    org_id: invite_prefill?.fields.sender_org_id || "",
    policy_id: policy_id ? policy_id : undefined,
    additional_options: additional_email_cc_options,
  });

  return (
    <>
      <Typography variant="titleLarge">Invited By</Typography>

      <InviteFormGrid>
        {is_privileged_user && (
          <Container>
            <Spacing $height={24} />
            {invite_prefill?.meta?.sender_org_name ? (
              <FormRow $flexDirection="column">
                <FormLabel htmlFor={`org-id`}>
                  <Typography variant="titleSmall">
                    Inviting organisation
                  </Typography>
                </FormLabel>
                <Input
                  id="org-id"
                  readOnly={true}
                  value={invite_prefill.meta.sender_org_name}
                  disabled={true}
                />
              </FormRow>
            ) : (
              <Typography variant="bodyMedium" color="negative.text.default">
                Please ensure this policy is attached to an organisation before
                initiating an invitation.
              </Typography>
            )}
          </Container>
        )}
        <FormRow>
          <Container className="column">
            <InputWithValidation
              name="sender_first_name"
              rules={{
                required: {
                  value: true,
                  message: "Inviter's first name is required",
                },
              }}
              label="Inviter's first name"
              placeholder="Inviter's first name"
              control={control}
            />
          </Container>
          <Container className="column">
            <InputWithValidation
              name="sender_last_name"
              rules={{
                required: {
                  value: true,
                  message: "Inviter's last name is required",
                },
              }}
              label="Inviter's last name"
              placeholder="Inviter's last name"
              control={control}
            />
          </Container>
        </FormRow>
        <SelectWithValidation
          control={control}
          name="email_cc"
          label="CC'd emails"
          multiple={true}
          searchable={true}
          addOption={(email_option: IOption) => addEmailRecipient(email_option)}
          data={email_options.data}
          isLoading={email_options.isLoading}
          rules={{
            pattern: validation_rules.select.pattern(
              validation_rules.patterns.email,
              "Invalid email address",
            ),
          }}
          margin={{ top: 24, bottom: 24 }}
        />
        {is_privileged_user && (
          <SelectWithValidation
            control={control}
            name="email_bcc"
            label="BCC'd emails"
            multiple={true}
            searchable={true}
            addOption={(email_option: IOption) =>
              addEmailRecipient(email_option)
            }
            data={email_options.data}
            isLoading={email_options.isLoading}
            rules={{
              pattern: validation_rules.select.pattern(
                validation_rules.patterns.email,
                "Invalid email address",
              ),
            }}
            margin={{ bottom: 24 }}
          />
        )}
      </InviteFormGrid>
    </>
  );
};
